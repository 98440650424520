/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Banner',
        import: () => import('@stories/Widgets/Content/Banner/Banner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RichTextArea',
        import: () => import('@stories/Widgets/Content/RichTextArea/RichTextArea'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Navigation',
        import: () => import('@stories/Widgets/Navigation/SiteNavigation/SiteNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Footers/SiteFooter/SiteFooter'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PageTitle',
        import: () => import('@stories/Widgets/Content/PageTitle/PageTitle'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'IconList',
        import: () => import('@stories/Widgets/Media/IconList/IconList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ImageWithText',
        import: () => import('@stories/Widgets/Media/ImageWithText/ImageWithText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SectionTitle',
        import: () => import('@stories/Widgets/Media/SectionTitle/SectionTitle'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Timeline',
        import: () => import('@stories/Widgets/Media/Timeline/Timeline'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductList',
        import: () => import('@stories/Widgets/Products/ProductList/ProductList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'InteractiveRange',
        import: () => import('@stories/Widgets/Products/InteractiveRange/InteractiveRange'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'Split6040',
        import: () => import('@stories/Widgets/Content/Split6040/Split6040'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ContactForm',
        import: () => import('@stories/Widgets/Forms/ContactForm/ContactForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductBanner',
        import: () => import('@stories/Widgets/Content/ProductBanner/ProductBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ContentWithLogo',
        import: () => import('@stories/Widgets/Media/ContentWithLogo/ContentWithLogo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FeatureList',
        import: () => import('@stories/Widgets/Media/FeatureList/FeatureList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Video',
        import: () => import('@stories/Widgets/Media/Video/Video'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductPromoHeader',
        import: () => import('@stories/Widgets/Content/ProductPromoHeader/ProductPromoHeader'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CentreText',
        import: () => import('@stories/Widgets/Content/CentreText/CentreText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'StickyNavigation',
        import: () => import('@stories/Widgets/Navigation/StickyNavigation/StickyNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    {
        name: 'ImageCarousel',
        import: () => import('@stories/Widgets/Media/ImageCarousel/ImageCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductPromoTabs',
        import: () => import('@stories/Widgets/Media/ProductPromoTabs/ProductPromoTabs'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductFeaturePicker',
        import: () => import('@stories/Widgets/Content/ProductFeaturePicker/ProductFeaturePicker'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SplitCallout',
        import: () => import(/* webpackChunkName: "SplitCallout" */ '@stories/Widgets/Content/SplitCallout/SplitCallout'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TabbedCarouselContent',
        import: () => import(/* webpackChunkName: "TabbedCarouselContent" */ '@stories/Widgets/Content/TabbedCarouselContent/TabbedCarouselContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductOverview',
        import: () => import('@stories/Widgets/Products/ProductOverview/ProductOverview'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VariantInformation',
        import: () => import('@stories/Widgets/Products/VariantInformation/VariantInformation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RelatedProducts',
        import: () => import('@stories/Widgets/Products/RelatedProducts/RelatedProducts'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Breadcrumb',
        import: () => import(/* webpackChunkName: "Breadcrumb" */ '@stories/Widgets/Navigation/Breadcrumb/Breadcrumb'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CookieText',
        import: () => import(/* webpackChunkName: "CookieText" */ '@stories/Widgets/Content/CookieText/CookieText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CookiePolicy',
        import: () => import(/* webpackChunkName: "CookiePolicy" */ '@stories/Widgets/Content/CookiePolicy/CookiePolicy'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SearchResults',
        import: () => import(/* webpackChunkName: "SearchResults" */ '@stories/Widgets/Products/SearchResults/SearchResults'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
];
