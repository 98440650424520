import Cookie from 'js-cookie';
export default function () {
    const serverManifest = Cookie.get('ServerWebpackManifestHash');
    const clientManifest = Cookie.get('ClientWebpackManifestHash');
    if (serverManifest === undefined) {
        return;
    }
    if (clientManifest !== serverManifest) {
        Cookie.set('ClientWebpackManifestHash', serverManifest);
    }
}
